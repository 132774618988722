import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/"
import SEO from "../../../components/seo/"
import Crumbs from "../../../components/breadcrumb/"
import BackNav from "../../../components/back-nav/"

import Split from "../../../components/split/"
import TextBlock from "../../../components/text-block/"
import LinkBlock from "../../../components/link-block/"
import LogoBlock from "../../../components/logo-block/"

import { psColour, wlColour } from "../../../data/data.colours"

const WlambThanksPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			psLogo: file(relativePath: { eq: "logos/ps-logo.svg" }) {
				publicURL
			}
			psBg: file(relativePath: { eq: "backgrounds/google-map-aerial-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			wlLogo: file(relativePath: { eq: "logos/wl-logo.svg" }) {
				publicURL
			}
			wlBg: file(relativePath: { eq: "backgrounds/aston41-exterior-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			aboutBg: file(relativePath: { eq: "backgrounds/tree-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	return (
		<>
			<SEO title="Thanks"
				path={ location.pathname }/>
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}/>
				<BackNav
					hex={wlColour.hex}
					link={`/`}/>
				<Split>
					<TextBlock 
						title={`Thanks`}
						titleTag={ `h1` }
						text={`
							<p>Your message has been received</p>
						`}
						bgColour={wlColour.slug}/>
					<Split vertical={true}>
						<LogoBlock 
							colour={psColour.slug}
							hex={psColour.hex}
							brand={`Painesend`}
							logo={ data.psLogo.publicURL }
							background={ data.psBg.childImageSharp.fluid }
							link={ `${process.env.GATSBY_SISTER_SITE}/painesend` }
							offsite/>
						<LogoBlock 
							colour={wlColour.slug}
							hex={wlColour.hex}
							brand={`W Lamb`}
							logo={ data.wlLogo.publicURL }
							background={ data.wlBg.childImageSharp.fluid }
							link={`/w-lamb`}/>
						<LinkBlock
							hex={wlColour.hex}
							link={`/w-lamb/about`}
							text={`About us`}
							background={ data.aboutBg.childImageSharp.fluid }
							position={`45% 100%`} />
					</Split>
				</Split>
			</Layout>
		</>
	)
}

export default WlambThanksPage